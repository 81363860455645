import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import StepBar from './components/StepBar';
import RichTextEditor from './components/RichTextEditor';
import Loading from './components/Loading';
import './progressbar.css';
import { useAppContext } from './Provider/AppProvider';

function Step1() {
  const { state, setData } = useAppContext();
  const [count, setCount] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const targetTime = Math.floor(Math.random() * 3) + 3;

  useEffect(() => {
    if (isRunning && count < targetTime) {
      const interval = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setIsRunning(false);
    }
  }, [count, isRunning, targetTime]);

   const updateData = (content) => {
    const newData = { answer2: content }; // Example new data
    setData(newData);
  };

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      {
        isRunning? <Loading/>
        :
        <main className="py-12 grow">
          <StepBar index={2} className = "px-12"/>
          <div className="flex flex-wrap justify-center mt-12 flex-col items-center sm:w-2/3 w-4/5 mx-auto">

            <div className="   p-6 px-12 rounded-lg ">
              <div className="font-semibold text-gray-400 flex my-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 mr-2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                </svg>

                Question 2
              </div>

              {/* Question Prompt */}
              <div className="mb-6">
                <p className="font-sans text-2xl  text-gray-700">
                  What emerging trends in blockchain technology or DeFi do you think will significantly shape the industry in the next 2–3 years, and how would you leverage these insights to create innovative products?<br/>
                  <p className="text-xl">(This evaluates the candidate's market awareness, industry expertise, and ability to think forward.)</p>
                </p>
              </div>

              <div className="font-semibold text-gray-400 flex mt-12 text-left">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 mr-2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                </svg>
                Answer
              </div>
            </div>

            
            <RichTextEditor className="" setData={updateData} data={state.data.answer2}/>
            <Link className="mt-12 text-center bg-purple-600 text-white py-2 px-6 rounded-lg text-lg w-2/3" to="/video-questions/create/a51148e8420c41a4b4da9256933550d4">Save and continue</Link>
          </div>
        </main>
      }
      
      <Footer />
    </div>
  );
}

export default Step1;
