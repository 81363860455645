import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptions from './components/LanguageOptions';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';

const content1 = {
  title: "Responsibilities:",
  content: [
           "- Product Vision: Lead and define the product roadmap for blockchain initiatives, ensuring alignment with BingX’s strategic goals.",
           "- Market Insights: Stay on top of blockchain trends, competitor moves, and user needs to deliver products that stand out.",
           "- Team Leadership: Collaborate closely with developers, designers, and business teams to bring products from concept to launch.",
           "- Optimization: Continuously analyze product performance and drive improvements that enhance user experience and platform efficiency.",
      ]
}

const content2 = {
  title: "Requirements:",
  content: [
           "- Experience: 5+ years in product management, with a strong background in blockchain or crypto products.",
           "- Knowledge: Deep understanding of blockchain technology, DeFi, and crypto market dynamics.",
           "- Skills: Strong analytical skills, excellent communication, and an ability to translate complex tech into user-friendly products.",
           "- Leadership: Proven track record of leading cross-functional teams and delivering successful products.",

      ]
}

const companyURL = "http://bingx.com";


function App() {

  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])


  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote / On-site, US" salary="$200,000 - $250,000/Yr" company="BingX" companyURL={companyURL} />
          <LanguageOptions company="BingX" title="Blockchain Product Manager – Remote" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default App;
