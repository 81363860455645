import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';


const content1 = {
  title: "Key Responsibilities:",
  content: [
           "- Strategy Development: Design and implement systematic trading strategies for cryptocurrencies.",
           "- Research & Analysis: Identify profitable opportunities through in-depth quantitative research.",
           "- Market Monitoring: Adapt strategies based on market trends for optimal returns.",
           "- Collaborative Innovation: Work with research and tech teams to enhance algorithms and infrastructure.",
           "- Performance Tracking: Maintain thorough documentation and track performance for consistent improvement.",
      ]
}

const content2 = {
  title: "Qualifications:",
  content: [
           "- Experience: Minimum of two years in live crypto trading with a proven success record.",
           "- Expertise: Strong knowledge of blockchain, digital assets, and major trading platforms (e.g., Binance, Coinbase Pro, TradingView).",
           "- Market Insight: Deep understanding of market dynamics, risk management, and trading psychology.",
           "- Education: Degree in finance, mathematics, or computer science is a plus but not required.",
      ]
}

const companyURL="https://bingx.com/";

function AppTrader() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
    
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="New York, US" salary="$180,000 - $250,000/year & PnL" company="Bingx" companyURL={companyURL} />
          <LanguageOptionsTraders company="Bingx" title="Systematic Crypto Trader – Remote" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppTrader;
